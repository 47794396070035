//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'VerificationPromoModal',
  computed: {
    link: function link() {
      return this.$store.getters['lang/linkTo']({
        page: 'game',
        params: '#verify'
      });
    },
    depositsSum: function depositsSum() {
      return this.$store.getters['player/profile'].depositsSum;
    },
    depositsCount: function depositsCount() {
      return this.$store.getters['player/profile'].depositsCount;
    }
  },
  methods: {
    verificationGa: function verificationGa() {
      this.$store.gaVerificationPromotion.click({
        location: 'verification_offer',
        buttonName: 'secure_now'
      });
      this.$store.gaVerificationPromotion.verification({
        location: 'verification_offer',
        step: 'info'
      });
    }
  },
  created: function created() {
    this.$store.gaVerificationPromotion.openPopup({
      location: 'login',
      formName: 'verification_offer',
      quantity: this.depositsCount,
      limitSum: this.depositsSum
    });
  }
};